
import * as t from '@root/src/store/chisai/GCB2/actionTypes'
import Vue, { Component, PropType } from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import { Connector, Project } from '@/types/main'
import { EditMasterMode, EditMasterStep } from '@/types/GCB2'
import { EDIT_MASTER_TYPE } from '@/vars/GCB2'
import _, { isNil } from 'lodash'
import { replaceElementAtIndex } from '@/utils'
import TextStep from '@/components/scheduledReport/editSteps/TextStep.vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import { CONNECTOR_TYPES } from '@/vars/general'
import api from '@/api/api'
import { ScheduledReport, ScheduledReportData, ScheduledReportTemplate } from '../types/scheduledReport'
import NameStep from '../components/scheduledReport/editSteps/NameStep.vue'
import TemplateStep from '../components/scheduledReport/editSteps/TemplateStep.vue'
import SendChannelsStep from '../components/scheduledReport/editSteps/SendChannelsStep.vue'
import SendToStep from '../components/scheduledReport/editSteps/SendToStep.vue'
import TimeStep from '../components/scheduledReport/editSteps/TimeStep.vue'
import { v4 as uuidv4 } from 'uuid'

export default Vue.extend({
    components: {
        Breadcrumbs,
        SpinnerLoader,
        TextStep,
    },

    data: () => ({
        EDIT_MASTER_TYPE,
        loading: true as boolean,
        stepsIndexes: [] as number[] | number,
        activeStepsIndexes: [] as number[] | number,
        copyLoading: false as boolean,
        scheduledReportIdFromQuery: null as any,
        scheduledReport: {} as ScheduledReport | ScheduledReportData,
        scheduledReportTemplates: [] as ScheduledReportTemplate[],
        createOrEditLoading: false as boolean,
        mode: null as EditMasterMode | null,
        steps: [
            {
                id: 1,
                title: 'Название',
                contentComponent: NameStep,
                ref: 'setting-1',
                saved: false,
                saveBtnDisabled: false,
            },
            {
                id: 2,
                title: 'Шаблон отправки',
                contentComponent: TemplateStep,
                ref: 'setting-2',
                saved: false,
                saveBtnDisabled: false,
            },
            {
                id: 3,
                title: 'Канал отправки',
                contentComponent: SendChannelsStep,
                ref: 'setting-3',
                saved: false,
                saveBtnDisabled: false,
            },
            {
                id: 4,
                title: 'Кому отправлять',
                contentComponent: SendToStep,
                ref: 'setting-4',
                saved: false,
                saveBtnDisabled: false,
            },
            {
                id: 5,
                title: 'Время отправки',
                contentComponent: TimeStep,
                ref: 'setting-5',
                saved: false,
                saveBtnDisabled: false,
            },
        ] as EditMasterStep[],
    }),
    watch: {
        stepsIndexes(): void {
            if (this.mode === EDIT_MASTER_TYPE.create) {
                this.activeStepsIndexes = Array.isArray(this.stepsIndexes)
                    ? [...this.stepsIndexes]
                    : [this.stepsIndexes].filter(el => !isNil(el))
            }
        },
        'scheduledReport.sendChannels'(sendChannels: ScheduledReportData['sendChannels']) {
            if (this.loading) return
            if (sendChannels.includes('whatsappDh')) {
                this.scheduledReport.sendTo = this.scheduledReport.sendTo.filter(el => el.type !== 'phone')
            }
        },
        'scheduledReport.sendAtDay'(checked: boolean) {},
        'scheduledReport.templateId'(templateId: ScheduledReportData['templateId']) {
            if (this.loading) return
            const activeTemplate = this.scheduledReportTemplates.find(el => el.id === templateId)
            if (activeTemplate?.type !== 'weekly') {
                this.scheduledReport.sendAtDay = null
            }
            if (activeTemplate?.type === 'weekly') {
                this.scheduledReport.sendAtDay = 5
            }
        },

        $route: {
            immediate: true,
            deep: true,
            handler() {
                this.mode = this.$route.query.mode as EditMasterMode
                this.scheduledReportIdFromQuery = this.$route.query.id
            },
        },
    },
    computed: {
        title(): string {
            return this.mode === EDIT_MASTER_TYPE.create ? 'Новый Отчет' : 'Редактирование'
        },
        expandDisabled(): boolean {
            return this.mode === EDIT_MASTER_TYPE.edit
        },
        panelsIsMultiplie(): boolean {
            return this.mode === EDIT_MASTER_TYPE.edit
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        botConnector(): Connector {
            return this.project.connectors.find(
                el => el.connectorType === CONNECTOR_TYPES.whatsappBasis
            ) as Connector
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: 'HR платформа для Idol Face',
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Отправка Отчетов',
                    to: '',
                    disabled: true,
                },
            ]
        },

        savedSteps(): any[] {
            return this.steps.filter(el => el.saved)
        },
        availableStepsIds(): number[] {
            if (this.mode === EDIT_MASTER_TYPE.create) {
                const nextStepIndex =
                    this.steps.findIndex(el => el.id === this.savedSteps[this.savedSteps.length - 1]?.id) + 1
                const nextStep = this.steps[nextStepIndex]
                return [...this.savedSteps.map(el => el.id), nextStep?.id]
            } else {
                return this.steps.map(el => el.id)
            }
        },
        availableSteps(): EditMasterStep[] {
            return this.steps.filter(el => (el.showCondition ? el.showCondition(this.project) : true))
        },
        saveButtonDisabled(): boolean {
            return !Boolean(
                this.scheduledReport.sendAt &&
                    this.scheduledReport.sendChannels.length &&
                    this.scheduledReport.templateId &&
                    this.scheduledReport.timezone &&
                    this.steps.every(el => !el.saveBtnDisabled)
            )
        },
    },
    methods: {
        async init() {
            this.loading = true
            if (!this.mode) {
                this.mode = EDIT_MASTER_TYPE.create as EditMasterMode
                this.$router.push({
                    path: this.$router.currentRoute.path,
                    query: Object.assign({}, this.$router.currentRoute.query, {
                        mode: EDIT_MASTER_TYPE.create,
                    }),
                })
            }
            if (this.mode === EDIT_MASTER_TYPE.create) {
                this.scheduledReport = {
                    projectId: this.project.id,
                    sendAt: '',
                    sendChannels: [],
                    sendTo: [],
                    sendAtDay: null,
                    additionalParamsValues: {},
                    sendPrevDay: false,
                    templateId: null,
                    timezone: null,
                    title: '',
                }
                this.stepsIndexes = 0
            }
            if (this.mode === EDIT_MASTER_TYPE.edit && this.scheduledReportIdFromQuery) {
                this.scheduledReport = await api.scheduledReport
                    .getOneById({ projectId: this.project.id, id: this.scheduledReportIdFromQuery })
                    .then(res => res.data!)
                this.stepsIndexes = this.steps.map((el, i) => i)
            }
            this.scheduledReportTemplates = await api.scheduledReport
                .getTemplates(this.project.id)
                .then(res => res.data!)
            this.loading = false
        },
        saveStep(step: EditMasterStep, index: number) {
            this.steps = replaceElementAtIndex(this.steps, index, Object.assign({}, step, { saved: true }))
            const nextStepIndex = index + 1
            this.stepsIndexes = nextStepIndex
            this.activeStepsIndexes = [nextStepIndex]
        },
        clear(step: EditMasterStep) {
            switch (step.id) {
                case 1:
                    this.scheduledReport.title = ''
                    break
                case 2:
                    this.scheduledReport.templateId = null
                    this.scheduledReport.additionalParamsValues = {}
                    break
                case 3:
                    this.scheduledReport.sendChannels = []
                    break
                case 4:
                    this.scheduledReport.sendTo = [{ id: uuidv4(), type: 'phone', value: '' }]
                    break
                case 5:
                    this.scheduledReport.timezone = null
                    this.scheduledReport.sendAt = ''
                    this.scheduledReport.sendPrevDay = false
                    break
                default:
                    break
            }
            ;(this.$refs[step.ref] as any)[0].clear()
        },
        updateSaveButton(e: boolean, step: any, index: number) {
            this.steps = replaceElementAtIndex(
                this.steps,
                index,
                Object.assign({}, step, { saveBtnDisabled: !e })
            )
        },
        goToProject() {
            this.$router.push(`/project/${this.project.id}`)
        },
        async createScheduledReport() {
            this.createOrEditLoading = true
            const { data, error } = await api.scheduledReport.create(
                this.mapDataBeforeSave(this.scheduledReport)
            )
            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка при создании активации')
                this.createOrEditLoading = false
                return
            }
            this.createOrEditLoading = false
            this.goToProject()
        },
        async updateScheduledReport() {
            this.createOrEditLoading = true
            const { data, error } = await api.scheduledReport.update(
                this.scheduledReportIdFromQuery,
                this.mapDataBeforeSave(this.scheduledReport)
            )
            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка при создании активации')
                this.createOrEditLoading = false
                return
            }
            this.createOrEditLoading = false
            this.goToProject()
        },
        stepIsActive(i: number) {
            if (Array.isArray(this.activeStepsIndexes)) return this.activeStepsIndexes.includes(i)
            else return [this.activeStepsIndexes].includes(i)
        },
        mapDataBeforeSave(scheduledReport: ScheduledReport | ScheduledReportData) {
            return Object.assign({}, scheduledReport, {
                sendTo: this.scheduledReport.sendTo
                    .filter(el => el.value)
                    .map(el =>
                        el.type === 'phone' ? { ...el, value: el.value.replace(/[^0-9+]/g, '') } : el
                    ),
            })
        },
        getYesterday() {
            let yesterday = new Date().getDay() - 1
            if (!yesterday) {
                yesterday = 7
            }
            return yesterday
        },
    },

    async created() {
        await this.init()
    },
    mounted() {},
})
